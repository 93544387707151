<template>
  <div>
    <common-header></common-header>
    <div class="form-box warp">
      <div class="item">
        <label>商户名称</label>
        <input type="text" v-model="da.name" placeholder="请输入公司/商户名称">
      </div>
      <div class="item">
        <label>联系人</label>
        <input type="text" v-model="da.contacts" placeholder="请输入联系人姓名">
      </div>
      <div class="item">
        <label>联系电话</label>
        <input type="text" v-model="da.phone" placeholder="请输入联系电话">
      </div>
      <div class="item ft">
        <label>备注</label>
        <textarea placeholder="请输入内容" v-model="da.remark"></textarea>
      </div>
    </div>

    <div class="sub-btn jb-r-l" @click="subClick">提交</div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      da:{
        remark: '',
        phone: '',
        contacts: '',
        name: '',
      },
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions(['PostFormData']),
    subClick() {
      let { da } =this;
      if (da.name == '') {
        this.$toast('请输入商户名称');
      } else if (da.contacts == '') {
        this.$toast('请输入联系人');
      } else if (da.phone == '' || da.phone.length != 11) {
        this.$toast('联系电话格式错误');
      } else {
        this.PostFormData({
          code: 'ZSJM',
          jsonData: JSON.stringify(da),
          title: '招商加盟'
        }).then(()=> {
          this.da = {
            remark: '',
            phone: '',
            contacts: '',
            name: '',
          }
          this.$toast('提交成功,请等待工作人员联系！');
        })
      }
    },
  },
}
</script>
<style lang='less' scoped>
.sub-btn{
  width: 4.8rem;
  height: .85rem;
  line-height: .85rem;
  text-align: center;
  border-radius: .42rem;
  font-size: .34rem;
  color: #ffffff;
  margin: 1.3rem auto 0;
}
.form-box{
  padding-top: .2rem;
  .item{
    display: flex;
    align-items: center;
    &.ft{
      display: block;
      label{
        line-height: .9rem;
      }
    }
  }
  input{
    flex: 1;
    line-height: .9rem;
    border-bottom: 1px solid #f7f7f7;
    font-size: 0.3rem;
  }
  textarea{
    width: 100%;
    background: #f7f7f7;
    border-radius: .05rem;
    height: 2.3rem;
    padding: .2rem;
    font-size: 0.3rem;
  }
  label{
    width: 1.7rem;
    display: block;
  }
}
</style>